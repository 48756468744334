import { groupBy, isEmpty, omit } from "lodash-es"
import { EXPENSE } from "~/components/TradeExpenses"
import { EXPENSE_KINDS } from "~/constants/static.items"

export function expenseAmount({
  kind,
  buyerAmount,
  sellerAmount,
  facilitatorAmount,
  totalQuantity,
  finalQuantity,
  finalAmount,
  trades = 1,
}): number {
  if (kind === "amount_percent")
    return (
      ((buyerAmount + sellerAmount + facilitatorAmount) / 100) * finalAmount
    )
  if (kind === "cost_per_unit")
    return (buyerAmount + sellerAmount + facilitatorAmount) * totalQuantity
  if (kind === "deducted_cost_per_unit")
    return (buyerAmount + sellerAmount + facilitatorAmount) * finalQuantity
  return (buyerAmount + sellerAmount + facilitatorAmount) * trades
}

export function normalizeExpenses(expenses: any[], omitIds: boolean = false) {
  const expenseGroup = groupBy(expenses, "expense_parameter.id")
  return isEmpty(expenseGroup)
    ? [EXPENSE]
    : Object.keys(expenseGroup).map(key => {
        const expenses = expenseGroup[key]
        const farmer = expenses.find(e => e.paid_by === "seller")
        const trader = expenses.find(e => e.paid_by === "buyer")
        const facilitator = expenses.find(e => e.paid_by === "facilitator")
        const kind = EXPENSE_KINDS.find(
          e =>
            e.id === farmer?.kind ||
            e.id === trader?.kind ||
            e.id === facilitator?.kind
        )
        return {
          expense_parameter:
            farmer?.expense_parameter ||
            trader?.expense_parameter ||
            facilitator?.expense_parameter,
          seller: omitIds ? omit(farmer, ["id"]) : farmer,
          buyer: omitIds ? omit(trader, ["id"]) : trader,
          kind: kind,
          amount: (farmer?.amount || 0) + (trader?.amount || 0),
          facilitator: omitIds ? omit(facilitator, ["id"]) : facilitator,
        }
      })
}
