import { CaretDown } from "@phosphor-icons/react"
import { Button, Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { useReportModal } from "@vesatogo/grass-reportmodal"
import { useState } from "react"
import { useAuthorization } from "~/components/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { useAllDepartmentsQuery } from "~/generated/graphql"
import { usePayableSelector } from "~/pages/d/payable/PayableSelector"
import PayableImporter from "../../../components/PayableReceivableImporter" // Import the modal

const PayableActions = () => {
  const onOpen = useReportModal(store => store.onOpen)
  const { selected } = usePayableSelector()
  const [isImporterOpen, setImporterOpen] = useState(false)
  // Permission based importer
  const isShowImporter = useAuthorization(Permission.canUploadPayableReceivable)
  const canViewPayableReceivableReport = useAuthorization(
    Permission.ViewPayableReceivableReport
  )

  return (
    <>
      <Popover
        minimal
        arrow={false}
        className="p-0"
        trigger="mouseenter"
        interactive
        animation="fade"
        theme="light-border"
        placement="bottom"
        content={
          <Menu className="max-h-52">
            {canViewPayableReceivableReport && (
              <MenuItem
                onClick={() => {
                  onOpen(
                    [
                      {
                        key: "from_date",
                        label: "Date From",
                        type: "date",
                      },
                      {
                        key: "till_date",
                        label: "Date Till",
                        type: "date",
                      },
                      {
                        key: "payable_report_name",
                        label: "Payable Report Name",
                        placeholder: "Select Payable Report Name",
                        type: "staticSelect",
                        options: [
                          {
                            id: "advance_outstanding_report",
                            name: "Advance Outstanding Report",
                          },

                          {
                            id: "payable_settlement_report",
                            name: "Net Payable Settlement Report",
                          },
                          {
                            id: "payable_settlement_outstanding_report",
                            name: "Net Payable Settlement Outstanding Report",
                          },
                          {
                            id: "payable_settlement_outstanding_receipt_report",
                            name: "Net Payable Settlement Outstanding Receipt Report",
                          },
                        ],
                      },
                      {
                        key: "department",
                        label: "Department",
                        placeholder: "Select Department",
                        type: "querySelect",
                        dataHook: useAllDepartmentsQuery,
                      },
                    ],
                    `https://${
                      import.meta.env.VITE_REST_URL
                    }/reports/payable_receivable_report_email`,
                    {
                      multipleEmails: true,
                      successMessage: "Payable Report sent successfully",
                    }
                  )
                }}
              >
                Payable Report
              </MenuItem>
            )}

            {isShowImporter && (
              <MenuItem onClick={() => setImporterOpen(true)}>
                Payable Importer{" "}
                {selected?.charAt(0).toUpperCase() + selected?.slice(1)}
              </MenuItem>
            )}
          </Menu>
        }
      >
        <Button
          className="!bg-gray-300"
          intent="none"
          leftIcon={<CaretDown weight="fill" />}
        >
          Action
        </Button>
      </Popover>

      {isImporterOpen && (
        <PayableImporter
          isOpen={isImporterOpen}
          onClose={() => setImporterOpen(false)}
          loaderType="net_payable_advance"
        />
      )}
    </>
  )
}

export default PayableActions
