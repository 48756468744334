import { Funnel } from "@phosphor-icons/react"
import {
  Button,
  Checkbox,
  FilterDrawer,
  FormQuerySelector,
  FormSelector,
  RadioGroup,
} from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import { Dispatch, SetStateAction, useState } from "react"
import { TradeKinds } from "~/constants/Kinds"
import { Permission } from "~/constants/Permissions"
import {
  useAuthenticatedUserQuery,
  useSearchBaseUsersQuery,
  useUsersQuery,
} from "~/generated/graphql"

export type PayableFilterType = {
  patti_type?: any
  advance_type?: any
  advance_status?: any
  transaction_status?: any
  placed_by?: any
  date_from?: any
  date_to?: any
  seller?: any
  department?: any
}

type PayableFilterProps = {
  filter: PayableFilterType
  setFilter: Dispatch<SetStateAction<PayableFilterType>>
  hideAdvanceType?: boolean
  hideAdvanceStatus?: boolean
  hideTransactionStatus?: boolean
  hidePlacedBy?: boolean
  hideSaudaPavti?: boolean
  hideBookingPatti?: boolean
  hideStoragePatti?: boolean
  hideTradeKind?: boolean
  hideDepartment?: boolean
}
const PayableFilter = ({
  filter,
  setFilter,
  hideAdvanceType,
  hideAdvanceStatus,
  hideTransactionStatus,
  hidePlacedBy,
  hideSaudaPavti,
  hideBookingPatti,
  hideStoragePatti,
  hideTradeKind,
  hideDepartment,
}: PayableFilterProps) => {
  const [isOpen, setOpen] = useState(false)

  function inputProps(path: string) {
    return {
      value: filter[path],
      onChange: (e: any) => {
        setFilter(prev => ({
          ...prev,
          [path]: e?.target ? e.target.value : e,
        }))
      },
    }
  }

  function getAppliedFilters(prefix: string, filter: any) {
    const totalApplied = Object.keys(filter)?.filter?.(
      key => key.startsWith(prefix) && filter[key]
    )?.length
    return totalApplied ? `${totalApplied} Applied` : null
  }

  const [{ data }] = useAuthenticatedUserQuery()
  const me = data?.me

  const buyerGroups = ["Trader"]
  if (me?.permissions?.includes(Permission.SelectFarmerInBuyer)) {
    buyerGroups.push("Farmer")
  }

  const departments = data?.me?.agent?.departments?.map(
    item => item?.department
  )

  return (
    <>
      <Button
        onClick={() => {
          setOpen(prev => !prev)
        }}
        variant="outline"
        className="items-center"
      >
        <Funnel />
      </Button>
      <FilterDrawer
        tabs={[
          {
            id: 3,
            title: "Others",
            subtitle: getAppliedFilters("oth", filter),
            content: (
              <div className="flex flex-col gap-3">
                {!hideDepartment && (
                  <FormSelector
                    {...inputProps("department")}
                    options={departments}
                    label={"Department"}
                  />
                )}
                {!hideTradeKind && (
                  <RadioGroup
                    className={"w-full"}
                    label="Trade Kind"
                    {...inputProps("patti_type")}
                  >
                    {!hideSaudaPavti && (
                      <Checkbox
                        type={"radio"}
                        value={TradeKinds.SaudaPavti}
                        label="Sauda"
                      />
                    )}
                    <Checkbox
                      type={"radio"}
                      value={TradeKinds.HishobPatti}
                      label="Hishob"
                    />
                    {!hideBookingPatti && (
                      <Checkbox
                        type={"radio"}
                        value={TradeKinds.Booking}
                        label="Booking"
                      />
                    )}
                    {!hideStoragePatti && (
                      <Checkbox
                        type={"radio"}
                        value={TradeKinds.StoragePatti}
                        label="Storage"
                      />
                    )}
                    <Checkbox
                      type={"radio"}
                      value={TradeKinds.InwardPatti}
                      label="Inward"
                    />
                  </RadioGroup>
                )}
                {!hideAdvanceType && (
                  <RadioGroup
                    className={"w-full"}
                    label="Advance Type"
                    {...inputProps("advance_type")}
                  >
                    <Checkbox
                      type={"radio"}
                      value={"Supplier Advance"}
                      label="Supplier"
                    />
                    <Checkbox
                      type={"radio"}
                      value={"Transport Advance"}
                      label="Transport"
                    />
                  </RadioGroup>
                )}
                {!hideAdvanceStatus && (
                  <FormSelector
                    label="Advance Status"
                    options={[
                      { id: "verified", name: "Approved" },
                      { id: "rejected", name: "Rejected" },
                      { id: "pending", name: "Pending" },
                    ]}
                    {...inputProps("advance_status")}
                  ></FormSelector>
                )}
                <FormQuerySelector
                  label="Seller/ Customer"
                  serverSideQuery
                  // useWildCardsInQuery={!filter?.seller ? false : true}
                  dataHook={useSearchBaseUsersQuery}
                  variables={{ groups: ["Farmer", "Trader"] }}
                  {...inputProps("seller")}
                ></FormQuerySelector>
                {!hideTransactionStatus && (
                  <FormSelector
                    label="Transaction Status"
                    options={[
                      { id: "settled", name: "Settled" },
                      { id: "pending", name: "Pending" },
                    ]}
                    {...inputProps("transaction_status")}
                  ></FormSelector>
                )}
                {!hidePlacedBy && (
                  <FormQuerySelector
                    label="Placed By"
                    serverSideQuery
                    dataHook={useUsersQuery}
                    variables={{ groups: buyerGroups }}
                    {...inputProps("placed_by")}
                  ></FormQuerySelector>
                )}
                <FormDateInput
                  label="Created From"
                  {...inputProps("date_from")}
                />
                <FormDateInput label="Created To" {...inputProps("date_to")} />
              </div>
            ),
          },
        ]}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onApply={() => {
          setOpen(false)
        }}
        onClearAll={() => {
          setFilter({})
          setOpen(false)
        }}
      />
    </>
  )
}

export default PayableFilter
