import { useForm } from "@mantine/form"
import {
  Card,
  Checkbox,
  Flex,
  FormInput,
  FormSelector,
  MenuDivider,
  Modal,
  RadioGroup,
  SpinnerOverlay,
  TextArea,
} from "@vesatogo/grass-core"
import { inr } from "@vesatogo/utils"
import classNames from "classnames"
import { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { OperationContext } from "urql"
import { CustomToast } from "~/components/CustomToast"
import { TransactionHeader } from "~/components/Transaction/Header"
import {
  HistoryColumns,
  TransactionHistory,
} from "~/components/Transaction/TransactionHistory"
import {
  Invoice,
  useAddUpdateSharedEntityMutation,
  useInvoiceSettlementTransactionsQuery,
  useMapInvoiceTransactionsMutation,
} from "~/generated/graphql"

const INITIAL_STATE = {
  payment_type: "full",
  amount: 0,
  attachments: [],
  remaining: 0,
}

export const InvoiceTransactionModal = ({
  refetch,
  invoice,
  fetching = false,
  historyCols = "",
  historyColSpan = "",
  columns = [
    HistoryColumns.DATE?.accessor,
    HistoryColumns.PATTI_NO?.accessor,
    HistoryColumns.PATTI_TYPE?.accessor,
    HistoryColumns.ADVANCE_TYPE?.accessor,
    HistoryColumns.PAYMENT_MODE?.accessor,
    HistoryColumns.ADVANCE_AMOUNT?.accessor,
    HistoryColumns.TRANSACTION_ID?.accessor,
    HistoryColumns.ACCOUNT_NO.accessor,
    HistoryColumns.RELEASED_AMOUNT?.accessor,
    HistoryColumns.NARRATION?.accessor,
  ],
}: {
  refetch?: (opts?: Partial<OperationContext> | undefined) => void
  fetching?: boolean
  columns?: any[]
  invoice?: Invoice
  historyCols?: string
  historyColSpan?: string
}) => {
  //   const { invoiceId, showInvoice } = ;
  const [searchParams, setSearchParams] = useSearchParams()
  const advanceId = searchParams.get("advanceId")
  const id = searchParams.get("id")
  const isOpen = Boolean(searchParams.get("transaction")) || false
  const [, insertTransaction] = useMapInvoiceTransactionsMutation()
  const [, addUpdateSharedEntity] = useAddUpdateSharedEntityMutation()
  const form = useForm<any>({
    initialValues: INITIAL_STATE,
    validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
  })

  const [{ data: invoiceTransaction }] = useInvoiceSettlementTransactionsQuery({
    variables: {
      dept: invoice?.department?.codename || "",
      user: invoice?.buyer?.reference_id || "",
    },
    requestPolicy: "network-only",
  })

  const balanceTotal =
    invoiceTransaction?.transactions_for_invoice_settlement?.reduce(
      (acc, inv) => acc + (inv?.remaining_amount || 0),
      0
    )
  const transactions = invoice?.transactions
  const totalAmount = invoice?.buyer_payable || 0
  // const transactions = data?.advance_payment_by_pk?.transactions

  const paid = useMemo(() => {
    return transactions?.reduce(
      (acc, trans) => acc + trans?.amount,
      0
    ) as number
  }, [transactions])

  const remaining = useMemo(() => {
    return Number((totalAmount - paid).toFixed(2)) < 0
      ? 0
      : Number((totalAmount - paid).toFixed(2))
  }, [totalAmount, paid])

  const { values: state, setValues: setState } = form

  useEffect(() => {
    const totalTransactionsAmount = state?.transaction_id?.reduce(
      (acc, it) => acc + it?.amount,
      0
    )
    setState(val => ({
      ...val,
      amount:
        totalTransactionsAmount >= state?.remaining
          ? state?.remaining
          : totalTransactionsAmount || 0,
    }))
  }, [remaining])

  useEffect(() => {
    setState(val => ({
      ...val,
      remaining: remaining,
    }))
  }, [transactions])

  function getInputProps(key: string) {
    const inputProps = form.getInputProps(key)
    return {
      ...inputProps,
    }
  }
  // getInputProps("")?.onChange()

  const onClose = () => {
    searchParams.delete("transaction")
    searchParams.delete("advanceId")
    searchParams.delete("invoiceId")
    searchParams.delete("id")
    setSearchParams(searchParams)
    form.reset()
  }

  const onSubmit = async () => {
    const transaction = state
    if (transaction?.amount > remaining) {
      return CustomToast({
        title: "Please enter value less than " + remaining,
        intent: "danger",
      })
    }

    let bankId = undefined

    const { data: insertData, error } = await insertTransaction({
      invoiceId: invoice?.id,
      transactions: state?.transaction_id?.map(tr => tr?.id),
      remark: state?.narration,
    })

    if (error) {
      return CustomToast({
        title: "Something went wrong! " + error.message,
        intent: "danger",
      })
    }
    refetch?.({
      requestPolicy: "network-only",
    })
    onClose()

    return CustomToast({ title: "Successfully saved transaction!" })
  }

  return (
    <Modal
      bodyClassName="max-w-7xl overflow-visible"
      isOpen={isOpen}
      onClose={onClose}
      title={"Transaction Details"}
      footerClassName={classNames(remaining === 0 ? "!hidden" : "")}
      primaryActionButtonProps={{
        text: "Yes, Confirm",
        size: "sm",
        onClick: onSubmit,
      }}
      secondaryActionButtonProps={{
        text: "Cancel",
        variant: "outline",
        size: "sm",
        onClick: onClose,
      }}
    >
      <Card className="!rounded-none p-2 bg-gray-100 text-gray-800 ">
        <SpinnerOverlay show={fetching} />
        <TransactionHeader
          totalAmount={invoice?.buyer_payable || totalAmount}
          seller={invoice?.buyer?.name}
          created_at={invoice?.created_at}
          id={invoice?.id}
          // kind={}
          isInvoice
          remainingAmount={remaining}
          balancePlantTransactionAmount={balanceTotal}
        />
        {transactions?.length === 0 ? (
          <></>
        ) : (
          <>
            <MenuDivider />
            <TransactionHistory
              transactions={transactions}
              columns={columns}
              cols={historyCols}
              colSpan={historyColSpan}
            />
          </>
        )}
      </Card>

      <section className="p-4">
        <div className={classNames("grid grid-cols-3 gap-4")}>
          <RadioGroup
            label="Payment Type"
            required
            disabled
            {...getInputProps("payment_type")}
          >
            <Checkbox disabled type="radio" value={"full"}>
              Full Payment
            </Checkbox>
            <Checkbox disabled type="radio" value={"partial"}>
              Partial Payment
            </Checkbox>
          </RadioGroup>
          <FormInput
            {...getInputProps("amount")}
            label="Amount"
            disabled
            // disabled={application?.payment_type === "full"}
            type="number"
            required
          ></FormInput>

          <FormSelector
            // dataHook={useInvoiceSettlementTransactionsQuery}
            required
            isMulti
            label="Transaction Id"
            className={"col-span-3"}
            getOptionLabel={option => {
              return (
                <Flex className="justify-between">
                  <div className="text-gray-800">{option?.name}</div>{" "}
                  <div className="text-sm text-gray-700">
                    {inr(option?.amount)}
                  </div>
                </Flex>
              )
            }}
            options={invoiceTransaction?.transactions_for_invoice_settlement?.map(
              inv => {
                return {
                  id: inv?.id,
                  name: inv?.transaction_id,
                  amount: inv?.remaining_amount,
                }
              }
            )}
            {...getInputProps(`transaction_id`)}
            isOptionDisabled={op => state?.amount === state?.remaining}
            onChange={e => {
              setState(val => {
                const totalTransactionsAmount = e?.reduce(
                  (acc, it) => acc + it?.amount,
                  0
                )
                return {
                  ...val,
                  amount:
                    totalTransactionsAmount >= state?.remaining
                      ? state?.remaining
                      : totalTransactionsAmount,
                }
              })
              getInputProps(`transaction_id`)?.onChange(e)
              return
            }}
          />

          <div className="col-span-3">
            <TextArea
              className="w-full"
              label="Narration"
              {...getInputProps(`narration`)}
            />
          </div>
        </div>
      </section>
    </Modal>
  )
}
