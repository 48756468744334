import { CaretDown } from "@phosphor-icons/react"
import { Button, Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { useReportModal } from "@vesatogo/grass-reportmodal"
import { isEmpty } from "lodash-es"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import PayableImporter from "~/components/PayableReceivableImporter"
import GenerateTripModal from "~/components/VehicleConsolidation/GenerateTripModal"
import { TradeKinds } from "~/constants/Kinds"
import { useAllDepartmentsQuery } from "~/generated/graphql"
import { useTradeSelectorList } from "~/store/trade-selector.store"
const TradeActions = () => {
  const [params, setParams] = useSearchParams()
  const onOpen = useReportModal(store => store.onOpen)
  const [isImporterOpen, setImporterOpen] = useState("")
  const [isGenerateTripOpen, setGenerateTripOpen] = useState(false)

  const { selected, setSelected } = useTradeSelectorList()

  const bookingPattiList = Object.values(selected)?.filter(
    val => val?.kind === TradeKinds.Booking
  )
  const notBookingPattiList = Object.values(selected)?.filter(
    val => val?.kind !== TradeKinds.Booking
  )
  return (
    <>
      <Popover
        minimal
        arrow={false}
        className="p-0"
        trigger="mouseenter"
        interactive
        animation="fade"
        theme="light-border"
        placement="bottom"
        content={
          <Menu className="max-h-52">
            <MenuItem
              onClick={() => {
                onOpen(
                  [
                    {
                      key: "from_date",
                      label: "Date From",
                      type: "date",
                    },
                    {
                      key: "till_date",
                      label: "Date Till",
                      type: "date",
                    },
                    {
                      key: "trade_role",
                      label: "Trade Role",
                      type: "staticSelect",
                      options: [
                        {
                          id: "seller",
                          name: "Seller",
                        },
                        {
                          id: "buyer",
                          name: "Buyer",
                        },
                      ],
                    },
                    {
                      key: "department",
                      label: "Department",
                      type: "querySelect",
                      dataHook: useAllDepartmentsQuery,
                    },
                  ],
                  `https://${
                    import.meta.env.VITE_REST_URL
                  }/reports/trade_email/`,
                  {
                    multipleEmails: true,
                  }
                )
              }}
            >
              Trade Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                params.set("update", "all")
                setParams(params)
              }}
            >
              Update Trades
            </MenuItem>
            <MenuItem
              onClick={() => {
                params.set("action", "generate-invoice")
                setParams(params)
              }}
            >
              Generate Invoice For Trader
            </MenuItem>
            <MenuItem onClick={() => setImporterOpen("booking_patti")}>
              Booking Patti Importer
            </MenuItem>
            <MenuItem onClick={() => setImporterOpen("hishob_patti")}>
              Hishob Patti Importer
            </MenuItem>
            <MenuItem
              disabled={bookingPattiList?.length === 0}
              onClick={() => {
                if (notBookingPattiList?.length !== 0) {
                  for (const i of notBookingPattiList) {
                    setSelected({ id: i?.id })
                  }
                }
                setGenerateTripOpen(true)
              }}
            >
              Generate Trip
            </MenuItem>
          </Menu>
        }
      >
        <Button
          className="!bg-gray-300"
          intent="none"
          leftIcon={<CaretDown weight="fill" />}
        >
          Action
        </Button>
      </Popover>
      {isImporterOpen && (
        <PayableImporter
          isOpen={!isEmpty(isImporterOpen)}
          onClose={() => setImporterOpen("")}
          title={`${
            isImporterOpen === "booking_patti" ? "Booking" : "Hishob"
          } Patti Importer`}
          loaderType={
            isImporterOpen === "booking_patti"
              ? "booking_patti"
              : "hishob_patti"
          }
        />
      )}

      {isGenerateTripOpen && (
        <GenerateTripModal
          isOpen={isGenerateTripOpen}
          onClose={() => setGenerateTripOpen(false)}
        />
      )}
    </>
  )
}

export default TradeActions
