import { useForm } from "@mantine/form"
import { useLocalStorage } from "@mantine/hooks"
import { Info, List, Prohibit, Trash, Truck } from "@phosphor-icons/react"
import { openConfirmDialog } from "@vesatogo/grass-confirm-modal"
import {
  Button,
  Checkbox,
  CodenameTag,
  FormInput,
  FormQuerySelector,
  MenuDivider,
  Modal,
  Note,
  SegmentedControl,
  Tag,
  Toast,
} from "@vesatogo/grass-core"
import { FormDateInput } from "@vesatogo/grass-dates"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr } from "@vesatogo/utils"
import classNames from "classnames"
import dayjs from "dayjs"
import React, { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { ReactComponent as DeleteIcon } from "~/assets/trash.svg"
import { ReactComponent as TripIcon } from "~/assets/trip.svg"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import {
  useAddUpdateSetutradeTripMutation,
  useAddUpdateSharedEntityMutation,
  useAllDepartmentsQuery,
  useAllVehiclesQuery,
  useVehicleDetailQuery,
} from "~/generated/graphql"
import { useTradeSelectorList } from "~/store/trade-selector.store"

interface GenerateTripProps {
  isOpen: boolean
  onClose: () => void
}

type INITIAL_VALUES_TYPE = {
  vehicle?: any
  owner?: any
  driver?: any
  est_date?: any
  source?: any
  destination?: any
}

const INITIAL_VALUES: INITIAL_VALUES_TYPE = {
  vehicle: undefined,
  owner: undefined,
  driver: undefined,
  est_date: undefined,
  source: undefined,
  destination: undefined,
}

const GenerateTripModal: React.FC<GenerateTripProps> = ({
  isOpen,
  onClose,
}) => {
  const { selected, reset, setSelected } = useTradeSelectorList()
  const [deleteList, setDeleteList] = useState<any>([])

  const [dept] = useLocalStorage({ key: "department" })

  const [{ data: alldepts }] = useAllDepartmentsQuery()

  const currentDept = alldepts?.department?.find(
    dep => dep?.id?.toString() === dept?.toString()
  )

  const form = useForm({
    initialValues: INITIAL_VALUES,
  })

  const { values, setValues, getInputProps } = form

  const [{ data: vehicleDetail }] = useVehicleDetailQuery({
    variables: {
      id: values?.vehicle?.id,
    },
    pause: !values?.vehicle?.id,
  })

  const [{ fetching }, insertTrip] = useAddUpdateSetutradeTripMutation()
  const [{}, addUpdateSharedEntity] = useAddUpdateSharedEntityMutation()

  useEffect(() => {
    setValues(val => ({
      ...val,
      driver: vehicleDetail?.vehicle_detail?.driver
        ? vehicleDetail?.vehicle_detail?.driver
        : { full_name: undefined },
      owner: vehicleDetail?.vehicle_detail?.owner
        ? vehicleDetail?.vehicle_detail?.owner
        : { full_name: undefined },
    }))
  }, [vehicleDetail])

  const onSubmit = async () => {
    const { data: departmentData } = await addUpdateSharedEntity({
      name: currentDept?.name,
      group_name: SharedEntityGroup.KiteDepartment,
      reference_id: currentDept?.id,
    })

    const { data: vehicleData } = await addUpdateSharedEntity({
      name: values?.vehicle?.name,
      group_name: SharedEntityGroup.StarlingVehicle,
      reference_id: values?.vehicle?.name,
    })

    const { data, error } = await insertTrip({
      input: {
        trades: Object.values(selected)
          ?.filter(val => !val?.trip?.id)
          ?.map(val => val?.id),
        department_id: departmentData?.insert_setu_trade_shared_entity_one?.id,
        // driver_id: driverData?.insert_setu_trade_shared_entity_one?.id,
        driver_name: values?.driver?.full_name,
        driver_number: values?.driver?.number,
        aggregator_name: values?.owner?.full_name,
        aggregator_number: values?.owner?.number,
        estimated_date: dayjs(values?.est_date)?.format("YYYY-MM-DD"),
        vehicle_id: vehicleData?.insert_setu_trade_shared_entity_one?.id,
        destination_location_name: values?.destination,
        source_location_name: values?.source,
      },
    })

    if (error) {
      return toast.custom(
        <Toast
          intent="danger"
          title={"Something went wrong!" + error.message}
        />
      )
    }

    reset()
    onClose()
    return toast.custom(
      <Toast intent="primary" title={"Successfully created trip!"} />
    )
  }

  const COLUMNS = useMemo(() => {
    return [
      {
        accessor: "id",
        Header() {
          const isSelectAll =
            deleteList?.length === Object.values(selected)?.length
          return (
            <div className="flex gap-2">
              <Checkbox
                labelClassName="!mb-4"
                checked={isSelectAll}
                onChange={() => {
                  if (isSelectAll) {
                    setDeleteList([])
                    // reset()
                  } else {
                    setDeleteList(Object?.values(selected)?.map(it => it?.id))
                  }
                }}
              />
            </div>
          )
        },
        Cell({ value, row }) {
          const { selected, setSelected } = useTradeSelectorList()
          if (!row?.original?.id) return null

          return (
            <Checkbox
              checked={deleteList?.includes(value)}
              onChange={() => {
                if (deleteList?.includes(value)) {
                  setDeleteList(val => [...val?.filter(it => it !== value)])
                } else {
                  setDeleteList(val => [...val, value])
                }
              }}
            />
          )
        },
      },
      {
        Header: <div className="pl-3">Pavti No</div>,
        accessor: "name",
        Cell({ row: { original } }) {
          if (!original.id) return "--"
          return (
            <div
              className={classNames(
                "pl-4",
                original?.trip?.id ? "text-gray-600" : ""
              )}
            >
              Pavti #{original.id}
            </div>
          )
        },
      },

      {
        Header: "Created On",
        accessor: "created_at",
        Cell({ value, row: { original } }) {
          if (!value) return "--"
          return (
            <Cell
              titleClassName={original?.trip?.id ? "!text-gray-600" : ""}
              subtitleClassName={original?.trip?.id ? "!text-gray-400" : ""}
              title={dayjs(value).format("D MMM 'YY")}
              subtitle={dayjs(value).format("hh:mm a")}
            />
          )
        },
      },
      {
        Header: "Farmer",
        accessor: "seller.user[0]",
        Cell({ value, row: { original } }) {
          if (!value) return "--"
          return (
            <Cell
              titleClassName={original?.trip?.id ? "!text-gray-600" : ""}
              subtitleClassName={original?.trip?.id ? "!text-gray-400" : ""}
              title={
                <div className="flex items-center gap-1">
                  {value.full_name}{" "}
                  {value?.is_blocked && (
                    <Prohibit color="red" size={20} weight="fill" />
                  )}
                </div>
              }
              subtitle={
                <>
                  <CodenameTag referenceId={value.external_reference_id} />
                </>
              }
            />
          )
        },
      },
      {
        Header: "Trader",
        accessor: "buyer.user[0]",
        Cell({ value, row: { original } }) {
          if (!value) return "--"
          return (
            <Cell
              titleClassName={original?.trip?.id ? "!text-gray-600" : ""}
              subtitleClassName={original?.trip?.id ? "!text-gray-400" : ""}
              title={
                <div className="flex items-center gap-1">
                  {value.full_name}{" "}
                  {value?.is_blocked && (
                    <Prohibit color="red" size={20} weight="fill" />
                  )}
                </div>
              }
              subtitle={
                <CodenameTag referenceId={value.external_reference_id} />
              }
            />
          )
        },
      },
      {
        Header: "Commodity",
        accessor: "commodity",
        Cell({ value, row: { original } }) {
          if (!value) return "--"
          const variety = original?.commodity_variety
          return (
            <Cell
              titleClassName={original?.trip?.id ? "!text-gray-600" : ""}
              subtitleClassName={original?.trip?.id ? "!text-gray-400" : ""}
              title={value?.name}
              subtitle={variety?.name}
            />
          )
        },
      },
      {
        Header: "Quantity",
        accessor: "trade_items_aggregate.aggregate.sum.quantity",
        Cell({ value, row: { original } }) {
          if (!value) return "--"

          return (
            <Cell
              titleClassName={original?.trip?.id ? "!text-gray-600" : ""}
              subtitleClassName={original?.trip?.id ? "!text-gray-400" : ""}
              title={value || "0"}
            />
          )
        },
      },

      {
        Header: "Rate",
        accessor: "item_units",
        Cell({ value, row: { original } }) {
          if (!value) return "--"
          return (
            <Cell
              titleClassName={original?.trip?.id ? "!text-gray-600" : ""}
              subtitleClassName={original?.trip?.id ? "!text-gray-400" : ""}
              title={`${inr(
                original?.trade_items_aggregate?.aggregate.avg.rate
              )} / ${value[0]?.rate_unit?.name || "na"}`}
              subtitle={inr(original.total_amount)}
            />
          )
        },
      },
    ]
  }, [deleteList])

  const [tab, setTab] = useState<"trip" | "list">("trip")
  return (
    <Modal
      primaryActionButtonProps={
        tab === "list"
          ? {
              text: "Generate Trip",
              loading: fetching,
              disabled: !Object.values(selected)?.find(val => !val?.trip?.id),
              onClick: () => {
                if (!values?.vehicle) {
                  return toast.custom(
                    <Toast intent="danger" title={"Please Select Vehicle"} />
                  )
                }
                if (!values?.est_date) {
                  return toast.custom(
                    <Toast intent="danger" title={"Please Select Date"} />
                  )
                }
                openConfirmDialog({
                  isOpen: true,
                  onSubmit: onSubmit,
                  confirmationButtonText: "Yes, Confirm",
                  contentClassName: "bg-gray-100",
                  message: (
                    <div className="flex flex-col justify-center items-center">
                      <TripIcon />
                      <span>{"Do you want to generate the trip?"}</span>
                    </div>
                  ),
                  title: "Action",
                  primaryButtonProps: {
                    intent: "primary",
                  },
                })
              },
            }
          : {
              text: "Next",
              onClick: () => {
                if (!values?.vehicle) {
                  return toast.custom(
                    <Toast intent="danger" title={"Please Select Vehicle"} />
                  )
                }
                if (!values?.est_date) {
                  return toast.custom(
                    <Toast intent="danger" title={"Please Select Date"} />
                  )
                }
                setTab("list")
              },
            }
      }
      secondaryActionButtonProps={{
        text: "Cancel",
        onClick: onClose,
        variant: "ghost",
      }}
      bodyClassName="max-w-5xl overflow-visible"
      // contentClassName="min-h-[60vh]"
      title={"Generate Trip"}
      onClose={onClose}
      isOpen={isOpen}
    >
      <section>
        <div className="flex justify-between">
          <SegmentedControl
            value={tab}
            onChange={value => {
              if (value === "list") {
                setTab("list")
              } else if (value === "trip") {
                setTab("trip")
              }
            }}
            data={[
              {
                label: "Trip",
                value: "trip",
                icon: <Truck size={18} className="mr-1" />,
              },
              {
                label: "Booking Patti List",
                value: "list",
                icon: <List size={18} weight="bold" className="mr-1" />,
              },
            ].filter(b => b)}
          />
          <Button
            hidden={tab !== "list"}
            intent="danger"
            disabled={deleteList?.length === 0}
            onClick={() => {
              const onSubmit = () => {
                if (deleteList?.length !== 0) {
                  for (const i of deleteList) {
                    setSelected({ id: i })
                    setDeleteList([])
                  }
                } else {
                  reset()
                }
              }
              openConfirmDialog({
                isOpen: true,
                onSubmit: onSubmit,
                confirmationButtonText: "Yes, Confirm",
                message: (
                  <div className="flex flex-col justify-center items-center">
                    <DeleteIcon />
                    <span>{"Do you want to delete the plot?"}</span>
                  </div>
                ),
                // bodyClassName: "!w-[40%]",
                title: "Action",
              })
            }}
            variant="outline"
            leftIcon={<Trash className="" />}
            text={"Remove"}
          />
        </div>
        {tab === "trip" && (
          <>
            <div className="my-4 flex flex-col gap-4">
              <div className="flex gap-2">
                <Tag intent="info" variant="outline">
                  Department: {currentDept?.name}
                </Tag>
                {/* <Tag intent="info" variant="outline">
                  Source Depot: Nashik
                </Tag>
                <Tag intent="info" variant="outline">
                  Destination Depot: Nashik
                </Tag> */}
              </div>
              <div>
                <h1 className="text-xs font-600">Transport Details</h1>
                <MenuDivider />
                <div className="grid grid-cols-3 gap-2">
                  <FormQuerySelector
                    label="Vehicle"
                    required
                    dataHook={useAllVehiclesQuery}
                    variables={{
                      first: 10,
                    }}
                    {...getInputProps("vehicle")}
                  />
                  <FormInput
                    label="Transporter"
                    disabled
                    {...getInputProps("owner.full_name")}
                  />
                  <FormInput
                    label="Driver"
                    disabled
                    {...getInputProps("driver.full_name")}
                  />
                </div>
              </div>
              <div>
                <h1 className="text-xs font-600">Depot </h1>
                <MenuDivider />
                <div className="grid grid-cols-3 gap-2">
                  <FormInput
                    label="Dispatch Location"
                    {...getInputProps("source")}
                  />
                  <FormInput
                    label="Delivery Location"
                    {...getInputProps("destination")}
                  />
                </div>
              </div>
              <div>
                <h1 className="text-xs font-600">Operations </h1>
                <MenuDivider />
                <div className="grid grid-cols-3 gap-2">
                  <FormDateInput
                    label="Estimated Date"
                    required
                    {...getInputProps("est_date")}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {tab === "list" && (
          <>
            <Note
              intent="info"
              className="mt-4 mx-4 !border-l-4 border-blue-300 font-400"
            >
              <Info className="mr-1" weight="fill" size={18} />
              Booking patti already added to the trip should be displayed as
              disabled and should not be selectable or added again.
            </Note>
            <TableView
              relative={false}
              className="!h-[calc(50vh)]"
              columns={COLUMNS}
              getRowProps={({ original }) => ({
                className:
                  original?.seller?.user[0]?.is_blocked ||
                  original?.buyer?.user[0]?.is_blocked
                    ? "bg-red-100 hover:bg-red-100"
                    : original?.trip?.id
                    ? "bg-gray-200 hover:bg-gray-200 "
                    : "",
              })}
              data={Object.values(selected) || []}
              // meta={<>{totalTrades} trade requests</>}
            />
          </>
        )}
      </section>
    </Modal>
  )
}

export default GenerateTripModal
