import { CaretDown } from "@phosphor-icons/react"
import { Button, Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { useReportModal } from "@vesatogo/grass-reportmodal"
import { useAuthorization } from "~/components/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { useAllDepartmentsQuery } from "~/generated/graphql"

const ReceivableActions = () => {
  const onOpen = useReportModal(store => store.onOpen)
  const canViewPayableReceivableReport = useAuthorization(
    Permission.ViewPayableReceivableReport
  )

  return (
    <>
      <Popover
        minimal
        arrow={false}
        className="p-0"
        trigger="mouseenter"
        interactive
        animation="fade"
        theme="light-border"
        placement="bottom"
        content={
          <Menu className="max-h-52">
            {canViewPayableReceivableReport && (
              <MenuItem
                onClick={() => {
                  onOpen(
                    [
                      {
                        key: "from_date",
                        label: "Date From",
                        type: "date",
                      },
                      {
                        key: "till_date",
                        label: "Date Till",
                        type: "date",
                      },
                      {
                        key: "payable_report_name",
                        label: "Receivable Report Name",
                        placeholder: "Select Receivable Report Name",
                        type: "staticSelect",
                        options: [
                          {
                            id: "plant_settlement_report",
                            name: "Plant Settlement Report",
                          },

                          {
                            id: "receivable_settlement_report",
                            name: "Receivable Settlement Report",
                          },
                          {
                            id: "receivable_settlement_outstanding_report",
                            name: "Receivable Settlement Outstanding Report",
                          },
                        ],
                      },
                      {
                        key: "department",
                        label: "Department",
                        placeholder: "Select Department",
                        type: "querySelect",
                        dataHook: useAllDepartmentsQuery,
                      },
                    ],
                    `https://${
                      import.meta.env.VITE_REST_URL
                    }/reports/payable_receivable_report_email`,
                    {
                      multipleEmails: true,
                      successMessage: "Receivable Report sent successfully",
                    }
                  )
                }}
              >
                Receivable Report
              </MenuItem>
            )}
          </Menu>
        }
      >
        <Button
          className="!bg-gray-300"
          intent="none"
          leftIcon={<CaretDown weight="fill" />}
        >
          Action
        </Button>
      </Popover>
    </>
  )
}

export default ReceivableActions
