import { useForm } from "@mantine/form"
import { useDebouncedValue, useLocalStorage } from "@mantine/hooks"
import {
  CheckCircle,
  CircleHalf,
  MagnifyingGlass,
  Prohibit,
  Timer,
} from "@phosphor-icons/react"
import {
  Avatar,
  Breadcrumbs,
  CodenameTag,
  InputGroup,
  Tag,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr } from "@vesatogo/utils"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import TradeKindTag from "~/components/TradeKindTag"
import { TradeKinds } from "~/constants/Kinds"
import { siteConfig } from "~/constants/config"
import { AppRoutes } from "~/constants/routes"
import {
  useAllTradeRequestsQuery,
  useAuthenticatedUserQuery,
  useBookingTradeSummaryQuery,
  useTripDetailQuery,
} from "~/generated/graphql"
import { FilterType } from "~/pages/d/trade-requests"
import TripFilter from "~/pages/d/trade-requests/trip/TripFilters"
import { getBookingQuantity } from "~/utils/bookingPavtiUtils"

type INITIAL_VALUE_TYPE = {
  trip_id: { label?: string; value?: any }
  vehicle_no: { label?: string; value?: any }
  owner: { label?: string; value?: any }
  driver: { label?: string; value?: any }
  source: { label?: string; value?: any }
  destination: { label?: string; value?: any }
  est_date: { label?: string; value?: any }
  trader: { label?: string; value?: any }
  quantity: { label?: string; value?: any }
  amount: { label?: string; value?: any }
}

const INITIAL_VALUES: INITIAL_VALUE_TYPE = {
  trip_id: { label: "Trip Id" },
  vehicle_no: { label: "Vehicle No" },
  owner: { label: "Owner" },
  driver: { label: "Driver" },
  source: { label: "Source" },
  destination: { label: "Destination" },
  est_date: { label: "Estimated Date" },
  trader: { label: "Trader Name" },
  quantity: { label: "Quantity" },
  amount: { label: "Total Amount" },
}

const COLUMNS = [
  {
    Header: <div className="pl-3">Pavti No</div>,
    accessor: "name",
    Cell({ row: { original } }) {
      if (!original.id) return "--"
      return (
        <div className="pl-4">
          <Link
            className="link-text text-left"
            to={`/d/trade-requests/${original.id}`}
          >
            Pavti #{original.id}
          </Link>
        </div>
      )
    },
  },
  {
    Header: "Pavti Type",
    accessor: "kind",
    Cell({ value }) {
      return <TradeKindTag kind={value as any} />
    },
  },
  {
    Header: "Created On",
    accessor: "created_at",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Cell
          title={dayjs(value).format("D MMM 'YY")}
          subtitle={dayjs(value).format("hh:mm a")}
        />
      )
    },
  },
  {
    Header: "Farmer",
    accessor: "seller.user[0]",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Cell
          title={
            <div className="flex items-center gap-1">
              {value.full_name}{" "}
              {value?.is_blocked && (
                <Prohibit color="red" size={20} weight="fill" />
              )}
            </div>
          }
          subtitle={
            <>
              <CodenameTag referenceId={value.external_reference_id} />
            </>
          }
        />
      )
    },
  },

  {
    Header: "Commodity",
    accessor: "commodity",
    Cell({ value, row: { original } }) {
      if (!value) return "--"
      const variety = original?.commodity_variety
      return <Cell title={value?.name} subtitle={variety.name} />
    },
  },
  {
    Header: "Quantity",
    accessor: "trade_items_aggregate.aggregate.sum.quantity",
    Cell({ value }) {
      if (!value) return "--"

      return <Cell title={value || "0"} />
    },
  },
  {
    Header: "Remaining Quantity",
    accessor: "trades_aggregate.aggregate.sum.total_quantity",
    id: "remaining_quantity",
    Cell({ value, row: { original } }) {
      if (original?.kind === TradeKinds.HishobPatti || !value) {
        return "--"
      }
      const remainingQuantity = original?.total_quantity - value

      return <Cell title={remainingQuantity} />
    },
  },
  {
    Header: "Rate",
    accessor: "item_units",
    Cell({ value, row: { original } }) {
      if (!value) return "--"
      return (
        <Cell
          title={`${inr(original.trade_items_aggregate.aggregate.avg.rate)} / ${
            value[0]?.rate_unit?.name || "na"
          }`}
          subtitle={inr(original.total_amount)}
        />
      )
    },
  },
  {
    Header: "Receipt",
    accessor: "receipt_id",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Link
          className="link-text text-left"
          to={AppRoutes.tradeReceiptDetail(value)}
        >
          Receipt #{value}
        </Link>
      )
    },
  },
  {
    Header: "invoice",
    accessor: "invoice",
    Cell({ value }) {
      if (!value) return "--"
      return (
        <Cell title={`#${value?.id}`} subtitle={value?.current_status_id} />
      )
    },
  },
  {
    Header: "Placed By",
    accessor: "created_by[0]",
    Cell({ value, row }) {
      if (!value) return "--"
      return (
        <Tooltip
          content={
            <div>
              <p>{value?.full_name || "na"}</p>
              <p className="text-gray-400">
                {dayjs(row?.original?.created_at).format("DD MMM 'YY | h:mm a")}
              </p>
            </div>
          }
        >
          <Avatar
            className="relative text-center"
            name={value?.full_name}
            src={value?.photo?.url}
          />
        </Tooltip>
      )
    },
  },
  {
    Header: "Status",
    accessor: "current_status_id",
    Cell({ row: { original }, value }) {
      let remainingQuantity = 0
      {
        if (original?.kind === TradeKinds.Booking) {
          const [{ data: bData }] = useBookingTradeSummaryQuery({
            variables: {
              id: original.id,
            },
            pause: original?.kind !== TradeKinds.Booking,
            requestPolicy: "network-only",
          })

          remainingQuantity = getBookingQuantity(
            bData,
            original?.trade_items[0]?.variety_grade?.id
          )
        }
      }

      return (
        <Cell
          title={
            value ? (
              <span className="flex gap-1 items-center">
                {value}{" "}
                {original?.status_processing && (
                  <Tooltip content="Status change in progress">
                    <Timer size={16} className="text-blue-300" weight="fill" />
                  </Tooltip>
                )}
                {original?.kind === TradeKinds.Booking &&
                  (remainingQuantity > 0 &&
                  remainingQuantity <
                    original?.trade_items_aggregate.aggregate.sum.quantity ? (
                    <CircleHalf
                      size={16}
                      weight="fill"
                      className="text-blue-300"
                    />
                  ) : remainingQuantity === 0 ? (
                    <CheckCircle
                      size={16}
                      weight="fill"
                      className="text-green-300"
                    />
                  ) : (
                    <></>
                  ))}
              </span>
            ) : (
              "na"
            )
          }
          subtitle={dayjs(original.updated_at).format("D MMM 'YY | hh:mm A")}
        />
      )
    },
  },
]

export const TripDetail = () => {
  const [params, setParams] = useSearchParams()

  const { id } = useParams()
  const buyerId = params.get("buyerId")
  const search = params.get("search")
  const [filter, setFilter] = useState<FilterType>({})

  const [debouncedSearch] = useDebouncedValue(
    search?.trim(),
    siteConfig.debounce
  )

  const [{ data }] = useTripDetailQuery({
    variables: {
      id: parseInt(id as string),
    },
    pause: !id,
  })

  const [{ data: aData }] = useAuthenticatedUserQuery()
  const createdAt = filter?.oth_created_at
  const [department] = useLocalStorage({ key: "department" })
  const currentDeptCodename = aData?.me?.agent?.current_department?.codename
  const buyerCode = filter.sb_buyer?.codename
  const sellerCode = filter.sb_seller?.codename
  const commodityCode = filter.com_commodity?.codename
  const varietyCode = filter.com_variety?.codename
  const facilitatorCode = filter.oth_facilitator?.codename

  const [{ data: allTrades, fetching }] = useAllTradeRequestsQuery({
    variables: {
      where: {
        id: {
          _in: data?.setutrade_trip_by_pk?.trades?.map(val => val?.id),
          _eq: debouncedSearch ? debouncedSearch : undefined,
        },
        buyer: {
          id: {
            _eq: buyerId ? buyerId : undefined,
          },
        },
        ...(debouncedSearch
          ? Number(debouncedSearch)
            ? {}
            : { tracking_code: { _ilike: `%${debouncedSearch}%` } }
          : {}),
        kind: { _eq: filter.oth_kind },
        created_at: createdAt
          ? {
              _lte: dayjs(createdAt).endOf("day").toISOString(),
              _gte: dayjs(createdAt).startOf("day").toISOString(),
            }
          : undefined,
        department: {
          reference_id: {
            _eq: (department && String(department)) || currentDeptCodename,
          },
        },

        seller: sellerCode
          ? {
              reference_id: {
                _eq: sellerCode,
              },
            }
          : undefined,
        commodity: commodityCode && {
          reference_id: {
            _eq: commodityCode,
          },
        },
        commodity_variety: varietyCode && {
          reference_id: {
            _eq: varietyCode,
          },
        },
        mode: {
          _eq: filter.oth_mode,
        },
        facilitator: facilitatorCode && {
          reference_id: {
            _eq: facilitatorCode,
          },
        },
        // status: {
        //   _eq: filter.oth_status?.id,
        // },
        current_status_id: {
          _eq: filter.oth_status?.id,
        },
      },
    },
    pause: !data?.setutrade_trip_by_pk?.trades?.length,
    requestPolicy: "network-only",
  })

  const form = useForm({
    initialValues: INITIAL_VALUES,
  })

  const { values, setValues } = form

  useEffect(() => {
    setValues(val => ({
      ...val,
      amount: {
        ...val?.amount,
        value: inr(
          allTrades?.trade?.reduce(
            (acc, val) => acc + val?.deducted_amount,
            0
          ) || 0
        ),
      },
      destination: {
        ...val?.destination,
        value: data?.setutrade_trip_by_pk?.destination_location_name,
      },

      driver: {
        ...val?.driver,
        value: data?.setutrade_trip_by_pk?.driver_name,
      },
      est_date: {
        ...val?.est_date,
        value: dayjs(data?.setutrade_trip_by_pk?.estimated_date)?.format(
          "DD MMM 'YY"
        ),
      },
      owner: {
        ...val?.owner,
        value: data?.setutrade_trip_by_pk?.aggregator_name,
      },
      quantity: {
        ...val?.quantity,
        value: allTrades?.trade?.reduce(
          (acc, val) => acc + val?.deducted_quantity,
          0
        ),
      },
      source: {
        ...val?.source,
        value: data?.setutrade_trip_by_pk?.source_location_name,
      },
      trader: {
        ...val?.trader,
        value: allTrades?.trade?.[0]?.buyer?.user?.[0]?.full_name,
      },
      trip_id: {
        ...val?.trip_id,
        value: `#${data?.setutrade_trip_by_pk?.id}`,
      },
      vehicle_no: {
        ...val?.vehicle_no,
        value: data?.setutrade_trip_by_pk?.vehicle?.name,
      },
    }))
  }, [data])

  return (
    <>
      <header className="bg-gray-100 shadow-sm p-3 border-b-gray-300 border-b flex justify-between">
        <Breadcrumbs
          items={[
            {
              text: "Trip",
              link: AppRoutes.tradeTrips,
              isActive: false,
            },
            {
              text: "Booking Patti’s List",
              link: AppRoutes.tradeTripDetail(id || "new"),
              isActive: true,
            },
          ]}
          linkRenderer={(link, item) => {
            return <Link to={link as any}>{item}</Link>
          }}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search pavti no",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
          <TripFilter filter={filter} setFilter={setFilter} />
        </div>
      </header>

      <section className="!h-[80vh] m-3 bg-white p-4">
        <div>
          <h1>Trip Info.</h1>
          <div className="flex gap-2 flex-wrap">
            {Object.keys(values)?.map(key => {
              return (
                <Tag intent="info" variant="outline">
                  {values?.[key]?.label}: {values[key]?.value || "na"}
                </Tag>
              )
            })}
          </div>
        </div>

        <TableView
          isLoading={fetching}
          data={allTrades?.trade || []}
          columns={COLUMNS}
        />
      </section>
    </>
  )
}

export default TripDetail
