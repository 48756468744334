import { useForm } from "@mantine/form"
import { useDebouncedValue, useLocalStorage } from "@mantine/hooks"
import { List, MagnifyingGlass, Plus, Receipt } from "@phosphor-icons/react"
import {
  Avatar,
  AvatarGroup,
  Button,
  CodenameTag,
  InputGroup,
  SegmentedControl,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr } from "@vesatogo/utils"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAuthorization } from "~/components/AuthorizationProvider"
import { PlantTransaction } from "~/components/Transaction/PlantTransaction"
import { PayableReceivableDate } from "~/constants/Payable"
import { Permission } from "~/constants/Permissions"
import { siteConfig } from "~/constants/config"
import { AppRoutes } from "~/constants/routes"
import {
  Order_By,
  useAuthenticatedUserQuery,
  usePlantSettlementQuery,
} from "~/generated/graphql"
import ReceivableActions from "~/pages/d/bank-reconciliation/ReceivableActions"
import PayableFilter, {
  PayableFilterType,
} from "~/pages/d/payable/PayableFilter"
import { config } from "~/pages/pavti/config"
import { inrFormatter } from "~/utils/inrFormatter"

const INITIAL_VALUES = {
  plant: undefined,
  attachments: [],
}

type ktype = {
  plant?: {
    primaryKey: any
  }
}

export const PlantSettlement = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState<PayableFilterType>({})
  const [params, setParams] = useSearchParams()
  const search = params.get("search")
  const [debouncedSearch] = useDebouncedValue(
    search?.trim(),
    siteConfig.debounce
  )
  const canViewPayableReceivableReport = useAuthorization(
    Permission.ViewPayableReceivableReport
  )

  const form = useForm<ktype>({
    initialValues: INITIAL_VALUES,
  })
  const { getInputProps, values: state, setFieldValue } = form

  function getFileUploadProps(
    key: string,
    maxFiles = 4,
    shouldEncrypt = false
  ) {
    const inputProps = form.getInputProps(key)
    return {
      media: inputProps.value,
      onChange: inputProps.onChange,
      maxFiles,
      shouldEncrypt,
      onError: toast.error,
    }
  }

  const page = Number(params.get("page")) || 1
  const [department] = useLocalStorage({ key: "department" })

  const [{ data: authData }] = useAuthenticatedUserQuery()
  const me = authData?.me

  // const [{data: allBanks}] = use
  useEffect(() => {
    setFieldValue("bank_name", undefined)
  }, [state?.plant?.primaryKey])
  const [{ data, fetching }, refetch] = usePlantSettlementQuery({
    variables: {
      limit: config.pageSize,
      offset: (page - 1) * config.pageSize,
      orderBy: {
        created_at: Order_By.DescNullsLast,
      },
      where: {
        department: {
          reference_id: filter?.department
            ? {
                _eq: filter?.department?.codename,
              }
            : undefined,
        },
        ...(filter?.placed_by
          ? {
              created_by_id: {
                _eq: filter?.placed_by?.id?.toString(),
              },
            }
          : undefined),
        created_at: {
          _lte: filter?.date_to ? dayjs(filter?.date_to).endOf("d") : undefined,
          _gte: filter?.date_from
            ? dayjs(filter?.date_from).startOf("d")
            : undefined,
          _gt: dayjs(PayableReceivableDate).startOf("d"),
        },
        user: filter?.seller?.codename
          ? {
              reference_id: {
                _eq: filter?.seller?.codename,
              },
            }
          : undefined,
        _or: [
          {
            user: debouncedSearch
              ? {
                  name: {
                    _ilike: `%${debouncedSearch}%`,
                  },
                }
              : undefined,
          },
          {
            department: debouncedSearch
              ? {
                  name: {
                    _ilike: `%${debouncedSearch}%`,
                  },
                }
              : undefined,
          },
          {
            transactions: debouncedSearch
              ? {
                  transaction_id: {
                    _ilike: `%${debouncedSearch}%`,
                  },
                }
              : undefined,
          },
        ],
      },
    },
  })

  const totalTrades =
    data?.department_settlements_aggregate?.aggregate?.count || 0

  const COLUMNS = [
    {
      Header: "Created On",
      accessor: "created_at",
      Cell({ value }) {
        // if (!value) return "--"
        return (
          <Cell
            title={dayjs(value).format("D MMM 'YY")}
            subtitle={dayjs(value).format("hh:mm a")}
          />
        )
      },
      formatter: () => {
        return "Total"
      },
    },
    {
      Header: "Plant Name",
      accessor: "department.name",
      Cell({ value }) {
        return <Cell title={value} />
      },
    },
    {
      Header: "Customer",
      accessor: "user.user.0",
      Cell({ value }) {
        if (!value) return "--"
        return (
          <Cell
            title={value.full_name}
            subtitle={
              <CodenameTag referenceId={value.external_reference_id2} />
            }
          />
        )
      },
    },

    {
      Header: "Bank Name",
      accessor: "transactions.0.bank.payment_detail",
      id: "seller",
      Cell({ value }) {
        if (!value) return "--"
        return (
          <Cell
            title={value?.bank_name}
            subtitle={"Acc #" + value?.account_number}
          />
        )
      },
    },

    {
      Header: "Transaction Id",
      accessor: "transactions.0",
      Cell({ value }) {
        if (!value?.id) return "--"
        return (
          <Cell title={value?.transaction_id} subtitle={value?.mode?.name} />
        )
      },
    },

    {
      Header: "Received Amount",
      accessor: "transactions.0.amount",
      Cell({ value }) {
        if (!value) return "--"
        return (
          <Cell titleClassName="!text-green font-700" title={inr(value || 0)} />
        )
      },
      aggregate: "sum",
      formatter: inrFormatter.format,
    },
    {
      Header: "Balance Amount",
      accessor:
        "transactions.0.child_transactions_aggregate.aggregate.sum.amount",
      Cell({ value, row: { original } }) {
        const total = original?.transactions?.[0]?.amount || 0
        return (
          <Cell
            titleClassName="!text-green font-700"
            title={inr(total - value || 0)}
          />
        )
      },
      aggregate: "sum",
      formatter: inrFormatter.format,
    },

    {
      Header: "Attachments",
      accessor: "transactions.0",
      id: "attachments",
      Cell({ value, row }) {
        if (!value) return "--"
        return (
          <AvatarGroup>
            {value?.attachments?.map(att => {
              return <Avatar key={att?.id} src={att.media?.url}></Avatar>
            })}
          </AvatarGroup>
        )
      },
    },
    {
      Header: "Narration",
      accessor: "transactions.0.remark",
      Cell({ value }) {
        if (!value) return "--"
        return (
          <Cell
            title={
              <>
                <Tooltip content={value}>
                  <div>
                    {value?.length > 15
                      ? value?.substring(0, 15) + "..."
                      : value}
                  </div>
                </Tooltip>
              </>
            }
          />
        )
      },
    },

    {
      Header: "Placed By",
      accessor: "created_by",
      Cell({ value, row }) {
        if (!value) return "--"
        return (
          <Tooltip
            content={
              <div>
                <p>{value?.full_name || "na"}</p>
                <p className="text-gray-400">
                  {dayjs(row?.original?.created_at).format(
                    "DD MMM 'YY | h:mm a"
                  )}
                </p>
              </div>
            }
          >
            <Avatar
              className="relative text-center"
              name={value?.full_name}
              src={value?.photo?.url}
            />
          </Tooltip>
        )
      },
    },
  ]

  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"plant"}
          onChange={value => {
            if (value === "invoice") {
              navigate(AppRoutes.invoiceSettlement)
            }
          }}
          data={[
            {
              label: "Plant Settlement",
              value: "plant",
              icon: <List className="mr-1" />,
            },
            {
              label: "Invoice Settlement",
              value: "invoice",
              icon: <Receipt className="mr-1" />,
            },
          ].filter(b => b)}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            leftElement={<MagnifyingGlass />}
            inputProps={{
              value: search || "",
              placeholder:
                "Search by Plant name, Customer name, Transaction ID",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
          />
          <PayableFilter
            filter={filter}
            setFilter={setFilter}
            hideAdvanceStatus
            hideAdvanceType
            hideTransactionStatus
            hideTradeKind
          />
        </div>
        <div className="flex gap-2">
          <div>
            {canViewPayableReceivableReport ? <ReceivableActions /> : null}
          </div>
          <Button
            text="Transaction"
            leftIcon={<Plus />}
            onClick={() => {
              params.set("addPlantTransaction", "true")
              setParams(params)
            }}
          />
        </div>
      </header>

      <PlantTransaction
        getInputProps={getInputProps}
        state={state}
        resetForm={form.reset}
        refetch={refetch}
        getFileUploadProps={getFileUploadProps}
        setFieldValue={form.setFieldValue}
      />
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        paginationProps={{
          total: Math.ceil(totalTrades / siteConfig.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        isLoading={fetching}
        columns={COLUMNS}
        data={data?.department_settlements || []}
        meta={<>{totalTrades} results</>}
        aggregateColumnClassName={
          "bg-blue-100 hover:bg-blue-100 p-10 sticky bottom-0 z-[8] font-600"
        }
      />
    </>
  )
}

export default PlantSettlement
