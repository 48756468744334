import { Divider, Tag } from "@vesatogo/grass-core"
import { inr } from "@vesatogo/utils"
import dayjs from "dayjs"
import TradeKindTag from "~/components/TradeKindTag"

export type TransactionHeaderType = {
  seller?: any
  created_at?: Date
  id?: number
  idLabel?: String
  kind?: any
  parameter_name?: String
  totalAmount: number
  advanceAmount?: number
  remainingAmount?: number
  balancePlantTransactionAmount?: number
  isInvoice?: boolean
}
export const TransactionHeader = ({
  seller,
  created_at,
  id,
  idLabel = "Patti",
  kind,
  parameter_name,
  totalAmount,
  advanceAmount,
  remainingAmount,
  balancePlantTransactionAmount,
  isInvoice,
}: TransactionHeaderType) => {
  return (
    <div className="flex gap-3 text-[14px]">
      {seller && (
        <>
          <div>
            <span className=" font-700 pl-2">{seller}</span>
          </div>
          <Divider />
        </>
      )}

      {created_at && (
        <>
          <div>
            Date
            <span className=" font-700 pl-2">
              {dayjs(created_at).format("DD MMM 'YY")}
            </span>
          </div>
          <Divider />
        </>
      )}
      <div className="flex gap-1">
        {id && (
          <div>
            {idLabel} #<span className="font-700 pl-2">{id}</span>
          </div>
        )}
        {kind && (
          <div>
            <TradeKindTag kind={kind as any} />
          </div>
        )}
        {parameter_name && (
          <>
            <Tag intent={"warning"} className="!rounded-xl" variant="outline">
              {parameter_name}
            </Tag>
            <Divider />
          </>
        )}
      </div>

      {totalAmount ? (
        <>
          <div>
            Total Amount
            <span className="font-700 pl-2">{inr(totalAmount || 0)}</span>
          </div>
          <Divider />
        </>
      ) : undefined}
      {advanceAmount ? (
        <>
          <div>
            Advance Amount
            <span className="text-green font-700 pl-2">
              {inr(advanceAmount || 0)}
            </span>
          </div>
          <Divider />
        </>
      ) : (
        <></>
      )}

      <div>
        {`${isInvoice ? "Receivable" : "Payable"} Amount`}
        <span className="text-red font-700 pl-2">
          {inr(remainingAmount || 0)}
        </span>
      </div>
      <Divider />

      {balancePlantTransactionAmount ? (
        <>
          <div>
            Balance Plant Amount
            <span className="text-green font-700 pl-2">
              {inr(balancePlantTransactionAmount || 0)}
            </span>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
