import { useDebouncedValue, useLocalStorage } from "@mantine/hooks"
import {
  Circle,
  DotsThreeVertical,
  List,
  MagnifyingGlass,
  Receipt,
} from "@phosphor-icons/react"
import {
  Avatar,
  CodenameTag,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  SegmentedControl,
  Tag,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr } from "@vesatogo/utils"
import classNames from "classnames"
import dayjs from "dayjs"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAuthorization } from "~/components/AuthorizationProvider"
import { RemarkModal } from "~/components/Remark/RemarkModal"
import TradeKindTag from "~/components/TradeKindTag"
import { TransactionModal } from "~/components/Transaction/TransactionModal"
import { PayableReceivableDate } from "~/constants/Payable"
import { Permission } from "~/constants/Permissions"
import { siteConfig } from "~/constants/config"
import { AppRoutes } from "~/constants/routes"
import { TRADE_KINDS } from "~/constants/static.items"
import {
  Order_By,
  useAdvancePaymentDetailQuery,
  useAllAdvancePaymentsQuery,
  useAuthenticatedUserQuery,
  useUpdateAdvancePaymentMutation,
} from "~/generated/graphql"
import PayableActions from "~/pages/d/payable/PayableActions"
import PayableFilter, {
  PayableFilterType,
} from "~/pages/d/payable/PayableFilter"
import {
  PayableSelector,
  usePayableSelector,
} from "~/pages/d/payable/PayableSelector"
import { config } from "~/pages/pavti/config"
import { inrFormatter } from "~/utils/inrFormatter"

const AdvancePayable = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState<PayableFilterType>({})
  const [params, setParams] = useSearchParams()
  const { selected } = usePayableSelector()
  const isTrade = selected === "trade"
  const search = params.get("search")
  const advanceId = params.get("advanceId")
  const [debouncedSearch] = useDebouncedValue(
    search?.trim(),
    siteConfig.debounce
  )

  const page = Number(params.get("page")) || 1
  const [department] = useLocalStorage({ key: "department" })
  const isShowImporter = useAuthorization(Permission.canUploadPayableReceivable)
  const canViewPayableReceivableReport = useAuthorization(
    Permission.ViewPayableReceivableReport
  )

  const [{ data: authData }] = useAuthenticatedUserQuery()
  const me = authData?.me

  const [{ data: detailData, fetching: detailFetching }] =
    useAdvancePaymentDetailQuery({
      variables: {
        id: advanceId,
      },
      pause: !advanceId,
      requestPolicy: "network-only",
    })
  const [{ data, fetching }, refetch] = useAllAdvancePaymentsQuery({
    variables: {
      limit: config.pageSize,
      offset: (page - 1) * config.pageSize,
      orderBy: {
        created_at: Order_By.DescNullsLast,
      },
      where: {
        created_at: {
          _lte: filter?.date_to ? dayjs(filter?.date_to).endOf("d") : undefined,
          _gte: filter?.date_from
            ? dayjs(filter?.date_from).startOf("d")
            : undefined,
          _gt: dayjs(PayableReceivableDate).startOf("d"),
        },

        ...(filter?.placed_by
          ? {
              created_by_id: {
                _eq: filter?.placed_by?.id?.toString(),
              },
            }
          : undefined),
        is_verified: filter?.advance_status?.id
          ? filter?.advance_status?.id === "verified"
            ? {
                _eq: true,
              }
            : filter?.advance_status?.id === "rejected"
            ? {
                _eq: false,
              }
            : { _is_null: true }
          : undefined,
        parameter: filter?.advance_type
          ? {
              name: {
                _ilike: filter?.advance_type,
              },
            }
          : undefined,
        transactions: filter?.transaction_status?.id
          ? filter?.transaction_status?.id === "pending"
            ? undefined
            : {}
          : undefined,
        trade: isTrade
          ? {
              kind: filter?.patti_type
                ? {
                    _eq: filter?.patti_type,
                  }
                : undefined,
              department: {
                reference_id: {
                  _eq:
                    (department && String(department)) ||
                    me?.agent?.current_department?.codename,
                },
              },
              ...(debouncedSearch
                ? {
                    id:
                      debouncedSearch && Number(debouncedSearch)
                        ? {
                            _eq: Number(debouncedSearch),
                          }
                        : undefined,
                  }
                : undefined),

              seller:
                (debouncedSearch && !Number(debouncedSearch)) || filter?.seller
                  ? {
                      name:
                        debouncedSearch && !Number(debouncedSearch)
                          ? {
                              _ilike: `%${debouncedSearch}%`,
                            }
                          : undefined,
                      reference_id: filter?.seller
                        ? {
                            _eq: filter?.seller?.codename,
                          }
                        : undefined,
                    }
                  : undefined,
            }
          : undefined,
        receipt: !isTrade
          ? {
              department: {
                reference_id: {
                  _eq:
                    (department && String(department)) ||
                    me?.agent?.current_department?.codename,
                },
              },
              ...(debouncedSearch
                ? {
                    id:
                      debouncedSearch && Number(debouncedSearch)
                        ? {
                            _eq: Number(debouncedSearch),
                          }
                        : undefined,
                  }
                : undefined),

              seller:
                (debouncedSearch && !Number(debouncedSearch)) || filter?.seller
                  ? {
                      name:
                        debouncedSearch && !Number(debouncedSearch)
                          ? {
                              _ilike: `%${debouncedSearch}%`,
                            }
                          : undefined,
                      reference_id: filter?.seller
                        ? {
                            _eq: filter?.seller?.codename,
                          }
                        : undefined,
                    }
                  : undefined,
            }
          : undefined,
      },
    },
  })

  const totalTrades = data?.advance_payment_aggregate?.aggregate?.count || 0

  const COLUMNS = [
    {
      Header: "Created On",
      accessor: "created_at",
      Cell({ value }) {
        // if (!value) return "--"
        return (
          <Cell
            title={dayjs(value).format("D MMM 'YY")}
            subtitle={dayjs(value).format("hh:mm a")}
          />
        )
      },
      formatter: () => {
        return "Total"
      },
    },
    {
      Header: <div className="pl-3">{isTrade ? "Trade Id" : "Receipt Id"}</div>,
      id: "id",
      Cell({ row: { original } }) {
        const value = isTrade ? original?.trade?.id : original?.receipt?.id
        if (!value) return "--"
        return (
          <div className="pl-4">
            <div
              className="link-text text-left w-fit cursor-pointer"
              onClick={() => {
                params.set("transaction", "true")

                params.set("advanceId", original?.id)
                setParams(params)
              }}
            >
              #{value}
            </div>
          </div>
        )
      },
    },
    {
      Header: "Type",
      id: "kind",
      Cell({ row: { original } }) {
        const value = isTrade ? original?.trade?.kind : original?.receipt?.kind
        const kind = TRADE_KINDS.find(k => k.id === value)
        if (!kind?.name) return "--"

        return <TradeKindTag kind={kind?.id as any} />
      },
    },

    {
      Header: "Seller",
      accessor: "trade.seller.user[0]",
      id: "seller",
      Cell({ row: { original } }) {
        const value = isTrade
          ? original?.trade?.seller?.user?.[0]
          : original?.receipt?.seller?.user?.[0]
        if (!value) return "--"
        return (
          <Cell
            title={value.full_name}
            subtitle={
              <CodenameTag referenceId={value.external_reference_id2} />
            }
          />
        )
      },
    },

    {
      Header: "Advance Type",
      accessor: "parameter.name",
      Cell({ value }) {
        if (!value) return "--"
        return (
          <Tag intent="warning" variant="outline" className="!rounded-xl">
            {value}
          </Tag>
        )
      },
    },

    {
      Header: "Total Amount",
      accessor: isTrade ? "trade.seller_payable" : "receipt.seller_payable",
      Cell({ row: { original }, value }) {
        if (!value) return "--"
        return <Cell titleClassName="font-700 " title={inr(value)} />
      },
      aggregate: "sum",
      formatter: inrFormatter.format,
    },

    {
      Header: "Advance Amount",
      accessor: "amount",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell titleClassName="font-700 !text-green" title={inr(value)} />
      },
      aggregate: "sum",
      formatter: inrFormatter.format,
    },

    {
      Header: "Advance Status",
      accessor: "is_verified",
      Cell({ value }) {
        const isVerified =
          value === true ? "Approved" : value === false ? "Rejected" : "Pending"
        return (
          <Cell
            titleClassName={classNames(
              "flex gap-1 items-center font-600 ",
              value === true
                ? "!text-green"
                : value === false
                ? "!text-red"
                : "!text-yellow"
            )}
            title={
              <>
                <Circle weight="fill" />
                {isVerified}
              </>
            }
          />
        )
      },
    },
    {
      Header: "Released Amount",
      accessor: "transactions_aggregate.aggregate.sum.amount",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell titleClassName="font-700 !text-green" title={inr(value)} />
      },
      aggregate: "sum",
      formatter: inrFormatter.format,
    },
    {
      Header: "Placed By",
      accessor: "created_by",
      Cell({ value, row }) {
        if (!value) return "--"
        return (
          <Tooltip
            content={
              <div>
                <p>{value?.full_name || "na"}</p>
                <p className="text-gray-400">
                  {dayjs(row?.original?.created_at).format(
                    "DD MMM 'YY | h:mm a"
                  )}
                </p>
              </div>
            }
          >
            <Avatar
              className="relative text-center"
              name={value?.full_name}
              src={value?.photo?.url}
            />
          </Tooltip>
        )
      },
    },
    {
      Header: "Transaction Status",
      accessor: "transactions",
      Cell({ row: { original }, value }) {
        const totalAmount = original?.amount
        const paid = value?.reduce((acc, trans) => acc + trans?.amount, 0)
        const remaining = Number((totalAmount - paid).toFixed(2))
        const isSettled = remaining === 0 || remaining < 0
        return (
          <Cell
            titleClassName={classNames(
              "flex gap-1 items-center font-600 ",
              isSettled ? "!text-green" : paid ? "!text-blue" : "!text-yellow"
            )}
            title={
              <>
                <Circle weight="fill" />
                {isSettled ? "Settled" : paid ? "Partial" : "Pending"}
              </>
            }
          />
        )
      },
    },
    {
      Header: "Last Transaction",
      accessor: "transactions.0.date",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell title={<>{dayjs(value).format("DD MMM 'YYYY")}</>} />
      },
    },
    {
      Header: "Remark",
      accessor: "remark",
      Cell({ value, row: { original } }) {
        const parentReceiptId = original?.transactions?.[0]
          ?.parent_transaction_id
          ? original?.transactions?.[0]?.trade?.receipt_id
          : undefined
        return (
          <>{parentReceiptId ? `Settled Receipt #${parentReceiptId}` : value}</>
        )
      },
    },
    {
      Header: "",
      accessor: "updated_at",
      Cell({ row: { original } }) {
        const [params, setParams] = useSearchParams()

        return (
          <Popover
            minimal
            arrow={false}
            className="p-0"
            trigger="mouseenter"
            interactive
            placement="bottom"
            content={
              <Menu className="max-h-72">
                <MenuItem
                  onClick={() => {
                    params.set("remark", original.id)
                    setParams(params)
                  }}
                >
                  Remark
                </MenuItem>
              </Menu>
            }
          >
            <button className="hover:bg-gray-300 rounded">
              <DotsThreeVertical size={20} />
            </button>
          </Popover>
        )
      },
    },
  ]

  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"advance"}
          onChange={value => {
            if (value === "net") {
              navigate(AppRoutes.netPayable)
            }
          }}
          data={[
            {
              label: "Advance",
              value: "advance",
              icon: <List className="mr-1" />,
            },
            {
              label: "Net Payable",
              value: "net",
              icon: <Receipt className="mr-1" />,
            },
          ].filter(b => b)}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            leftElement={<MagnifyingGlass />}
            inputProps={{
              value: search || "",
              placeholder: "Search by Patti no., Seller name",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
          />
          <PayableFilter filter={filter} setFilter={setFilter} hideDepartment />
        </div>
        <div className="flex gap-2">
          {isShowImporter || canViewPayableReceivableReport ? (
            <PayableActions />
          ) : null}
          <PayableSelector />
        </div>
      </header>
      <RemarkModal
        refetch={refetch}
        title="Remark"
        useAddRemarkMutation={useUpdateAdvancePaymentMutation}
      />
      <TransactionModal
        refetch={refetch}
        totalAmount={detailData?.advance_payment_by_pk?.amount}
        transactions={detailData?.advance_payment_by_pk?.transactions}
        tradeId={detailData?.advance_payment_by_pk?.trade?.id}
        receiptId={detailData?.advance_payment_by_pk?.receipt?.id}
        advanceAmount={detailData?.advance_payment_by_pk?.amount}
        createdAt={detailData?.advance_payment_by_pk?.created_at}
        headerTotalAmount={
          isTrade
            ? detailData?.advance_payment_by_pk?.trade?.seller_payable
            : detailData?.advance_payment_by_pk?.receipt?.seller_payable
        }
        kind={
          isTrade
            ? detailData?.advance_payment_by_pk?.trade?.kind
            : detailData?.advance_payment_by_pk?.receipt?.kind
        }
        objectId={
          isTrade
            ? detailData?.advance_payment_by_pk?.trade?.id
            : detailData?.advance_payment_by_pk?.receipt?.id
        }
        parameterName={detailData?.advance_payment_by_pk?.parameter?.name}
        sellerName={
          isTrade
            ? detailData?.advance_payment_by_pk?.trade?.seller?.user?.[0]
                ?.full_name || ""
            : detailData?.advance_payment_by_pk?.trade?.seller?.user?.[0]
                ?.full_name || ""
        }
        fetching={detailFetching}
        historyCols={`!grid-cols-9`}
        historyColSpan={`!col-span-7`}
        bankSelectorOptions={
          isTrade
            ? detailData?.advance_payment_by_pk?.trade?.seller?.user?.[0]
                ?.payment_details?.length !== 0
              ? detailData?.advance_payment_by_pk?.trade?.seller?.user?.[0]
                  ?.payment_details || []
              : detailData?.advance_payment_by_pk?.trade?.seller?.user?.[0]
                  ?.my_businesses?.[0]?.payment_details || []
            : detailData?.advance_payment_by_pk?.receipt?.seller?.user?.[0]
                ?.payment_details?.length !== 0
            ? detailData?.advance_payment_by_pk?.receipt?.seller?.user?.[0]
                ?.payment_details || []
            : detailData?.advance_payment_by_pk?.receipt?.seller?.user?.[0]
                ?.my_businesses?.[0]?.payment_details || []
        }
      />
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        paginationProps={{
          total: Math.ceil(totalTrades / siteConfig.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        isLoading={fetching}
        columns={COLUMNS}
        data={data?.advance_payment || []}
        meta={<>{totalTrades} results</>}
        aggregateColumnClassName={
          "bg-blue-100 hover:bg-blue-100 p-10 sticky bottom-0 z-[8] font-600"
        }
      />
    </>
  )
}

export default AdvancePayable
