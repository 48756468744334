import { useLocalStorage } from "@mantine/hooks"
import {
  CaretDown,
  CaretRight,
  Circle,
  DotsThreeVertical,
  List,
  MagnifyingGlass,
  Receipt,
  Truck,
} from "@phosphor-icons/react"
import {
  Avatar,
  CodenameTag,
  InputGroup,
  Menu,
  MenuItem,
  Modal,
  OverflowMenu,
  Popover,
  SegmentedControl,
  Tag,
  Toast,
  Tooltip,
} from "@vesatogo/grass-core"
import { Cell, TableView } from "@vesatogo/grass-table"
import { inr } from "@vesatogo/utils"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useAuthorization } from "~/components/AuthorizationProvider"
import GenerateInvoices from "~/components/GenerateInvoices"
import { Permission } from "~/constants/Permissions"
import SharedEntityGroup from "~/constants/SharedEntityGroup"
import { siteConfig } from "~/constants/config"
import { OrdersCustom } from "~/constants/icons"
import { APIRoutes, AppRoutes } from "~/constants/routes"
import {
  Order_By,
  useAddUpdateSharedEntityMutation,
  useAllTripsQuery,
  useAuthenticatedUserQuery,
  useBulkUpdateTradeMutation,
  useUpdateTripMutation,
} from "~/generated/graphql"
import UpdateTradeStatus from "~/pages/d/trade-requests/UpdateTradeStatus"
import { useTradeSelectorList } from "~/store/trade-selector.store"
import { downloadPdf } from "~/utils/downloadPdf"

function generateTripInvoice({
  tripId,
  traderId,
}: {
  tripId: string | number
  traderId?: string | number
}) {
  return () => {
    const token = localStorage.getItem("token")
    downloadPdf(
      traderId
        ? APIRoutes.downloadTripTraderInvoice({
            tripId: tripId,
            traderId: traderId,
          })
        : APIRoutes.downloadTripInvoice({ tripId: tripId }),
      token
    ).catch(err => {
      return toast.custom(
        <Toast title={"Unable to download the invoice"} intent="danger" />
      )
    })
  }
}

const AllTrips = () => {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()
  const [{ data: aData }] = useAuthenticatedUserQuery()

  const [shown, setShown] = useState(false)
  const search = params.get("search")
  const updatedAt = params.get("updatedAt")
  const page = Number(params.get("page")) || 1
  const [department] = useLocalStorage({ key: "department" })
  const canViewInvoice = useAuthorization(Permission.ViewInvoice)
  const canViewTrades = useAuthorization(Permission.ViewTrade)
  const [, upsertSharedEntity] = useAddUpdateSharedEntityMutation()
  const [, updateTrip] = useUpdateTripMutation()

  const currentDeptCodename = aData?.me?.agent?.current_department?.codename

  const [{ data, fetching }] = useAllTripsQuery({
    variables: {
      orderBy: {
        created_at: Order_By.DescNullsLast,
      },
      where: {
        id: {
          _eq: parseInt(search as string)
            ? parseInt(search as string)
            : undefined,
        },
        department: {
          reference_id: {
            _eq: (department && String(department)) || currentDeptCodename,
          },
        },
        _or: [
          {
            vehicle: search
              ? {
                  name: {
                    _ilike: `%${search}%`,
                  },
                }
              : undefined,
          },
          {
            trades: search
              ? { buyer: { name: { _ilike: `%${search}%` } } }
              : undefined,
          },
        ],
      },
    },
  })
  useEffect(() => {
    if (!canViewTrades && currentDeptCodename) {
      navigate(AppRoutes.tradeReceipts)
    }
  }, [canViewTrades, currentDeptCodename])

  const TRADER_COLUMNS = [
    {
      Header: "Trader",
      accessor: "buyers.0",
      className: "w-[10%]",
      Cell({ value, row: { original } }) {
        if (!value) return "--"
        const buyer = value?.buyer?.user?.[0]
        return (
          <Cell
            title={
              <div className="pl-4">
                <Link
                  className="link-text text-left"
                  to={{
                    pathname: AppRoutes.tradeTripDetail(original?.tripId),
                    search: `buyerId=${original?.buyerId}`,
                  }}
                >
                  {buyer?.full_name}
                </Link>
              </div>
            }
            subtitleClassName="ml-5"
            subtitle={
              <CodenameTag referenceId={buyer?.external_reference_id2 || ""} />
            }
          />
        )
      },
    },
    {
      Header: "Commodity",
      accessor: "buyers",
      className: "w-[27%]",
      Cell({ value }) {
        if (!value) return "--"
        const commodities: any = []
        for (let buyer of value) {
          if (
            !commodities?.filter(val => val?.id === buyer?.commodity?.id)
              ?.length
          )
            commodities.push(buyer?.commodity)
        }
        return (
          <Cell
            title={
              <OverflowMenu
                getItemProps={() => {
                  return {
                    intent: "primary",
                    variant: "minimal",
                  }
                }}
                items={commodities}
              />
            }
          />
        )
      },
    },

    {
      Header: "No. of Booking patti",
      accessor: "buyers",
      className: "w-[7%]",
      id: "number",
      Cell({ value }) {
        if (!value) return "--"

        return (
          <Cell
            title={
              <Tag className="!rounded-full" intent="info" variant="minimal">
                {value?.length}
              </Tag>
            }
          />
        )
      },
    },
    {
      Header: "Quantity",
      accessor: "buyers",
      className: "w-[4%]",
      id: "quantity",
      Cell({ value }) {
        if (!value) return "--"
        const quantity = value?.reduce(
          (acc, val) => acc + val?.deducted_quantity,
          0
        )
        return <Cell title={Number(quantity).toLocaleString()} />
      },
    },
    {
      Header: "Amount",
      accessor: "buyers",
      className: "w-[10%]",
      id: "amount",
      Cell({ value }) {
        if (!value) return "--"
        const amount = value?.reduce(
          (acc, val) => acc + val?.deducted_amount,
          0
        )
        return <Cell title={inr(amount)} />
      },
    },
    {
      Header: "",
      accessor: "updated_at",
      className: "w-[1%]",
      Cell({ row: { original } }) {
        return (
          <Popover
            minimal
            arrow={false}
            className="p-0"
            trigger="mouseenter"
            interactive
            animation="fade"
            theme="light-border"
            placement="bottom"
            content={
              <Menu className="max-h-72">
                <MenuItem
                  onClick={async () => {
                    const buyer = original?.buyers?.[0]?.buyer?.user?.[0]
                    const { data } = await upsertSharedEntity({
                      group_name: SharedEntityGroup.KiteUser,
                      name: buyer?.full_name,
                      reference_id: buyer?.reference_id,
                    })
                    return generateTripInvoice({
                      tripId: original?.tripId,
                      traderId: data?.insert_setu_trade_shared_entity_one?.id,
                    })()
                  }}
                >
                  Generate Transit Patti for Trader
                </MenuItem>
              </Menu>
            }
          >
            <button className="hover:bg-gray-300 rounded">
              <DotsThreeVertical size={20} />
            </button>
          </Popover>
        )
      },
    },
  ]

  const TRIP_COLUMNS = [
    {
      id: "expander",
      Header: "",
      Cell: ({ row }) =>
        row.depth === 0 ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <CaretDown size={16} />
            ) : (
              <CaretRight size={16} />
            )}
          </span>
        ) : null,
    },
    {
      Header: "Created On",
      accessor: "created_at",
      Cell({ value }) {
        if (!value) return "--"
        return (
          <Cell
            title={dayjs(value).format("D MMM 'YY")}
            subtitle={dayjs(value).format("hh:mm a")}
          />
        )
      },
    },
    {
      Header: <div className="pl-3">Trip Id</div>,
      accessor: "trip_id",
      Cell({ row: { original } }) {
        if (!original.id) return "--"
        return <Cell title={`Trip #${original?.id}`} />
      },
    },
    {
      Header: <div className="pl-3">Vehicle</div>,
      accessor: "vehicle",
      Cell({ value, row: { original } }) {
        if (!original.id) return "--"
        return <Cell title={value?.name} />
      },
    },
    {
      Header: "Transporter/Owner",
      accessor: "aggregator_name",
      Cell({ value, row: { original } }) {
        if (!value) return "--"
        return <Cell title={value} subtitle={original?.aggregator_number} />
      },
    },
    {
      Header: "Driver",
      accessor: "driver_name",
      Cell({ value, row: { original } }) {
        if (!value) return "--"
        return <Cell title={value} subtitle={original?.driver_number} />
      },
    },
    {
      Header: "Dispatch Location",
      accessor: "source_location_name",
      Cell({ value, row: { original } }) {
        if (!value) return "--"
        return <Cell title={value} />
      },
    },
    {
      Header: "Delivery Location",
      accessor: "destination_location_name",
      Cell({ value, row: { original } }) {
        if (!value) return "--"
        return <Cell title={value} />
      },
    },
    {
      Header: "Estimated Date",
      accessor: "estimated_date",
      Cell({ value }) {
        if (!value) return "--"
        return <Cell title={dayjs(value).format("D MMM 'YY")} />
      },
    },
    {
      Header: "Quantity",
      accessor: "trades",
      Cell({ value }) {
        if (!value) return "--"
        const totalQuantity = value?.reduce(
          (acc, val) => acc + val?.deducted_quantity,
          0
        )
        return <Cell title={Number(totalQuantity).toLocaleString() || "0"} />
      },
    },

    {
      Header: "Amount",
      accessor: "trades",
      id: "amount",
      Cell({ value, row: { original } }) {
        if (!value) return "--"
        const totalAmount = inr(
          value?.reduce((acc, val) => acc + val?.deducted_amount, 0)
        )
        return <Cell title={totalAmount || "0"} />
      },
    },

    {
      Header: "Placed By",
      accessor: "created_by[0]",
      Cell({ value, row }) {
        if (!value) return "--"
        return (
          <Tooltip
            content={
              <div>
                <p>{value?.full_name || "na"}</p>
                <p className="text-gray-400">
                  {dayjs(row?.original?.created_at).format(
                    "DD MMM 'YY | h:mm a"
                  )}
                </p>
              </div>
            }
          >
            <Avatar
              className="relative text-center"
              name={value?.full_name}
              src={value?.photo?.url}
            />
          </Tooltip>
        )
      },
    },

    {
      Header: "Status",
      accessor: "status",
      Cell({ value, row: { original } }) {
        if (!value) return "--"

        const isCompleted = value === "completed"
        return (
          <Cell
            titleClassName={isCompleted ? "!text-green" : "!text-blue"}
            title={
              <div className="flex gap-1 items-center font-600">
                <Circle
                  weight="fill"
                  className={isCompleted ? "text-green" : "text-blue"}
                />
                {isCompleted ? "Completed" : "Started"}
              </div>
            }
          />
        )
      },
    },

    {
      Header: "",
      accessor: "updated_at",
      Cell({ row: { original } }) {
        return (
          <Popover
            minimal
            arrow={false}
            className="p-0"
            trigger="mouseenter"
            interactive
            animation="fade"
            theme="light-border"
            placement="bottom"
            content={
              <Menu className="max-h-72">
                <MenuItem
                  onClick={generateTripInvoice({ tripId: original?.id })}
                >
                  Generate Trip Invoice
                </MenuItem>
                <MenuItem
                  disabled={original?.status === "completed"}
                  onClick={() => {
                    updateTrip({
                      where: {
                        id: {
                          _eq: original?.id,
                        },
                      },
                      set: {
                        status: "completed",
                      },
                    })
                  }}
                >
                  Complete Trip
                </MenuItem>
              </Menu>
            }
          >
            <button className="hover:bg-gray-300 rounded">
              <DotsThreeVertical size={20} />
            </button>
          </Popover>
        )
      },
    },
  ]

  const renderRowSubComponent = useCallback(({ row }) => {
    const subRows: any = []

    // Group trades by buyer ID
    const buyerMap = {}

    for (let trade of row?.original?.trades || []) {
      const buyerId = trade?.buyer?.id
      if (buyerId) {
        if (!buyerMap[buyerId]) {
          buyerMap[buyerId] = [] // Initialize an array for this buyer ID
        }
        buyerMap[buyerId].push(trade) // Add the buyer to the array
      }
    }

    // Convert buyerMap to an array of subRows
    for (const buyerId in buyerMap) {
      subRows.push({
        buyerId,
        buyers: buyerMap[buyerId], // Array of buyers for this buyerId
        tripId: row?.original?.id,
      })
    }

    return <SubRowAsync subRows={subRows} />
  }, [])

  const SubRowAsync = ({ subRows }) => {
    if (!subRows.length) return null
    return (
      <td colSpan={TRIP_COLUMNS?.length}>
        <TableView
          columns={TRADER_COLUMNS}
          data={subRows}
          className="!overflow-visible"
          tableClassName="!shadow-none !m-0 !overflow-visible"
          getRowProps={() => ({
            className: `bg-gray-100`,
          })}
        />
      </td>
    )
  }

  const totalTrades = 0
  useEffect(() => {
    if (updatedAt) {
      params.delete("updatedAt")
      setParams(params)
    }
  }, [updatedAt])
  return (
    <>
      <header className="flex justify-between p-2 border-b-1 border-b-gray-300">
        <SegmentedControl
          value={"trip"}
          onChange={value => {
            if (value === "receipts") {
              navigate(AppRoutes.tradeReceipts)
            } else if (value === "invoices" && canViewInvoice) {
              navigate(AppRoutes.tradeInvoices)
            } else if (value === "trades") {
              navigate(AppRoutes.tradeRequests)
            }
          }}
          data={[
            {
              label: "Trades",
              value: "trades",
              icon: <List className="mr-1" />,
            },
            {
              label: "Receipts",
              value: "receipts",
              icon: <Receipt className="mr-1" />,
            },
            canViewInvoice && {
              label: "Invoices",
              value: "invoices",
              icon: <OrdersCustom className="mr-1" />,
            },
            {
              label: "Trip",
              value: "trip",
              icon: <Truck className="mr-1" />,
            },
          ].filter(b => b)}
        />
        <div className="w-[500px] flex items-center gap-3">
          <InputGroup
            className={"w-full"}
            inputProps={{
              value: search || "",
              placeholder: "Search Trip ID, Vehicle no., Trader name",
              onChange(e) {
                params.set("search", e.target.value)
                setParams(params)
              },
            }}
            leftElement={<MagnifyingGlass />}
          />
          {/* <TradeRequestsFilter filter={filter} setFilter={setFilter} /> */}
        </div>
        <div className="w-[500px]"></div>
      </header>
      <TableView
        relative={false}
        className="!h-[calc(100vh-64px-57px)]"
        tableClassName="overflow-visible h-full bg-white"
        renderRowSubComponent={renderRowSubComponent}
        paginationProps={{
          total: Math.ceil(totalTrades / siteConfig.pageSize),
          page: page,
          onChange(page) {
            params.set("page", page.toString())
            params.set("search", "")
            setParams(params)
          },
        }}
        columns={TRIP_COLUMNS}
        isLoading={fetching}
        getRowProps={({ original }) => ({
          className:
            original?.seller?.user[0]?.is_blocked ||
            original?.buyer?.user[0]?.is_blocked
              ? "bg-red-100 hover:bg-red-100"
              : "",
        })}
        data={data?.setutrade_trip || []}
        meta={<>{totalTrades} trade requests</>}
      />
      <UpdateTradeStatus
        bulkUpdateHook={useBulkUpdateTradeMutation}
        storeListHook={useTradeSelectorList}
      />
      <GenerateInvoices />
      <Modal isOpen={shown} onClose={() => setShown(!shown)} />
    </>
  )
}

export default AllTrips
